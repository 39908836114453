import { useState } from "react";
import "./services.css";
import Send from "../../assets/send.svg";

export default function Services() {
    const [toggleState, setToggleState] = useState(0);
    const toggleTab = (index) => {
        setToggleState(index);
    };
    return (
        <section className="services section" id="services">
            <h2 className="section__title">My network</h2>
            <span className="section__subtitle"> Tab into my network and I'll help you uncover your potential </span>

            <div className="services__container container grid">
                {/* CONTENT 1 */}
                <div className="services__content">
                    <div>
                        <i className="uil uil-web-grid services__icon"></i>
                        <h3 className="services__title"> Ingenuity Solutions </h3>
                    </div>

                    <span className="services__button" onClick={() => toggleTab(1)}>
                        View More
                        <i className="uil uil-arrow-right services__button-icon"></i>
                    </span>

                    <div className={toggleState === 1 ? "services__modal active-modal" : "services__modal"}>
                        <div className="services__modal-content">
                            <i className="uil uil-times services__modal-close" onClick={() => toggleTab(0)}></i>

                            <h3 className="services__modal-title">Business & Software Consulting Agency</h3>
                            <p className="services__modal-description">
                             
                            </p>

                            <ul className="services__modal-services grid">
                                <li className="services__modal-service">
                                
                                    <p className="services__modal-info"> At Ingenuity Consulting Group, we not only deliver software solutions tailored to your specific needs but also provide in-depth research and strategic planning services.</p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">Timely delivery of quality work.</p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">Secure payment process, every time.</p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">Round-the-clock support.</p>
                                </li>
                            </ul>

                            <br/>

                            <a href= "https://www.linkedin.com/company/ingenuity-consulting-agency" className="button button--flex" target="_blank" rel="noopener noreferrer" >
                                LinkedIn 
                                <img src={Send} height={15} width={15} className="button__icon" alt="" />
                            </a>

                        </div>
                    </div>
                </div>

                {/* CONTENT 2 */}
                <div className="services__content">
                    <div>
                        <i className="uil uil-arrow services__icon"></i>
                        <h3 className="services__title">Startup <br /> Mentor</h3>
                    </div>

                    <span className="services__button" onClick={() => toggleTab(2)}>
                        View More
                        <i className="uil uil-arrow-right services__button-icon"></i>
                    </span>

                    <div className={toggleState === 2 ? "services__modal active-modal" : "services__modal"}>
                        <div className="services__modal-content">
                            <i className="uil uil-times services__modal-close" onClick={() => toggleTab(0)}></i>

                            <h3 className="services__modal-title">Startup Mentor </h3>
                            <p className="services__modal-description">
                                More than 2+ year of experience. Working as a mentor for Blackbird VCs. Helped 5+ startups founder in their journey.
                            </p>

                            <ul className="services__modal-services grid">
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">Validating the idea</p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">Technical advices</p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">Team building and more</p>
                                </li>
                            </ul>

                            <br/>

                            <a href= "https://blackbirdgiants.softr.app/view-mentor-profile?recordId=recVVaF0WjDpQiMB8" className="button button--flex" target="_blank" rel="noopener noreferrer" >
                                Book a session 
                                <img src={Send} height={15} width={15} className="button__icon" alt="" />
                            </a>
                        </div>
                    </div>
                </div>

                {/* CONTENT 3 */}
                <div className="services__content">
                    <div>
                        <i className="uil uil-edit services__icon"></i>
                        <h3 className="services__title">My <br /> Startup</h3>
                    </div>

                    <span className="services__button" onClick={() => toggleTab(3)}>
                        View More
                        <i className="uil uil-arrow-right services__button-icon"></i>
                    </span>

                    <div className={toggleState === 3 ? "services__modal active-modal" : "services__modal"}>
                        <div className="services__modal-content">
                            <i className="uil uil-times services__modal-close" onClick={() => toggleTab(0)}></i>

                            <h3 className="services__modal-title">My Startups and Upcoming Projects</h3>
                            <p className="services__modal-description">
                                More than 4+ year of building startup.
                            </p>

                            <ul className="services__modal-services grid">
                            <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">My Projects</p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">How I Build ThincHealth</p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">Journey with Fluidity</p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">Journey with Zen Tech Asia as a Head of Growth </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">Learn more about my startup journey</p>
                                </li>

                            </ul>
                            <br/>
                            <a href= "https://www.linkedin.com/in/tonynguyen0403" className="button button--flex" target="_blank" rel="noopener noreferrer" >
                                LinkedIn Me 
                                <img src={Send} height={15} width={15} className="button__icon" alt="" />
                            </a>
                        </div>
                    </div>
                </div>

                
            </div>
        </section>
    )
}
