import "./about.css";
import AboutImg from "../../assets/tony2.JPG";
import ResumePdf from "../../assets/Resume-Shubham-Lal.pdf";
// import FilesSVG from "../../assets/files.svg";
import Info from "./Info";
import Send from "../../assets/send.svg";

export default function About() {
    return (
        <section className="about section" id="about">
            <h2 className="section__title">About Me</h2>
            <span className="section__subtitle">My Insights</span>

            <div className="about__container container grid">
                <div className="about__data container grid" >
                    <img src={AboutImg} alt="Shubham-Second" className="about__img" />
                    <p className="section__subtitle__2"> Lost in Thought ? </p>
                </div>
                <p className="about__hobby">
                    Mentor | Founder |  Software Developer 
                </p>
                <div className="about__data">
                    
                    <Info />

                    <p className="about__description">
                        <span className="about__hobbies"> Mentor | Founder |  Software Developer </span>
                        <br/>
                        <span>I'm an entrepreneur building products that people love, and a mentor for life.
                        </span>
                        <br/>
                        I start my day reading the newspaper in the morning, a few hours later dive into building startup and work on helping other startups all day along.
                        
                        <span> <br/> -- Area of Interest: HealthTech, AI, Space -- </span>
                        {/* <span>I start my day reading newspaper in the morning, a few hours later dive into Game development for couple of hours and work on Websites all day along.</span>
                        At night, I practise some problems on Leetcode and Codeforces on the topics related to Data Structure and work out on some algorithm. */}
                    </p>

                    <a href = "https://www.linkedin.com/in/tonynguyen0403" className="button button--flex" target="_blank" rel="noopener noreferrer" >
                        LinkedIn me 
                        <img src={Send} height={24} width={24} className="button__icon" alt="" />
                    </a>

                    {/* <a href={ResumePdf} className="button button--flex" target="_blank" rel="noopener noreferrer" >
                        My Resume
                        <img src={FilesSVG} className="button__icon" alt="" />
                    </a> */}
                </div>
            </div>
        </section >
    )
}
