import { Icon } from '@iconify/react';

export default function Social() {
    return (
        <div className="home__social">

            <a href="https://www.linkedin.com/in/tonynguyen0403" className="home__social-icon" target="_blank" rel="noreferrer">
                <i className="uil uil-linkedin-alt"></i>
            </a>

            <a href="https://twitter.com/TonyNguyen0403" className="home__social-icon" target="_blank" rel="noreferrer">
                <i className="uil uil-twitter" color="blue"></i>
            </a> 

            {/* <a href="https://tonynguyen0403.substack.com/" className="home__social-icon" target="_blank" rel="noreferrer">
                <Icon icon="simple-icons:substack" color="orange" />
            </a> */}

            <a href="https://medium.com/@tonynguyen0403" className="home__social-icon" target="_blank" rel="noreferrer">
                <Icon icon="logos:medium-icon" color="black" />
            </a>
            
            <a href="mailto:tonynguyen0403@gmail.com" className="home__social-icon" target="_blank" rel="noreferrer">
                <i className="uil uil-google"></i>
            </a> 

            {/* <a href="https://tony-portfolio-website.vercel.app" className="home__social-icon" target="_blank" rel="noreferrer">
                {"</>"}
            </a>  */}

            {/* <a href="https://www.linkedin.com/in/tonynguyen0403" className="home__social-icon" target="_blank" rel="noreferrer">
                <i className="uil uil-google"></i>
            </a> */}

            {/* <a href="https://dribbble.com/shubhamlal" className="home__social-icon" target="_blank" rel="noreferrer">
                <i className="uil uil-dribbble"></i>
            </a> */}

            {/* <a href="https://stackoverflow.com/users/21035210/shubham-lal" className="home__social-icon" target="_blank" rel="noreferrer">
                <i className='bx bxl-stack-overflow' style={{fontWeight: "700", scale: "1.25"}}></i>
            </a> */}
        </div>
    )
}